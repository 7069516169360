import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 生成ppt报告
 * @param {Object} data
 */
export function createInspectPpt (data) {
  return request({
    url: '/ooh-msp/v2/msppreport/createinspectppt',
    method: 'get',
    params: data
  })
}

/**
 * 获取监播(上刊证明)报告生成列表
 * @param {Object} data
 */
export function getPptReportList (data) {
  return request({
    url: '/ooh-msp/v2/msppreport/getpptreportlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取报告模板
 * @param {Object} data
 */
export function getReportTemplateList (data) {
  return request({
    url: '/ooh-msp/v2/msppreport/getreporttemplatelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 下载线路在刊点位回传照片
 * @param {Object} data
 */
export function downloadAttch (data) {
  return request({
    url: '/ooh-msp/v2/msppreport/downloadattch',
    method: 'get',
    params: data
  })
}

/**
 * 生成下载列表文件
 * @param {Object} data
 */
export function createsettlementdetail (data) {
  return request({
    url: '/ooh-msp/v2/msppreport/createsettlementdetail',
    method: 'get',
    params: data
  })
}

/**
 * 发起批量下载文件
 * @param {Object} param
 * @returns
 */
export function downLoadTaskAttach (params) {
  return request.get('/ooh-msp/v2/msppreport/downLoadTaskAttach', { params })
}

/**
 * 批量下载任务回传照片
 * @param {Object} param
 * @returns
 */
export function getTaskAttachZip (params) {
  return request.get('/ooh-msp/v2/msppreport/getTaskAttachZip', params)
}
