import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取结算账单状态更新记录集合
 * @param {Object} data
 */
export function getBillStatusLog (data) {
  return request({
    url: '/ooh-statement/bill/getbillstatuslog',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 更新结算账单的状态
 * @param {Object} data
 */
export function updateBillStatus (data) {
  return request({
    url: '/ooh-statement/bill/updatebillstatus',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 确认对账
 * @param {Object} data
 */
export function confirmfeeitems (data) {
  return request({
    url: '/ooh-statement/v2/bill/confirmfeeitems',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取结算单列表
 * @param {Object} data
 */
export function getbillpage (data) {
  return request({
    url: '/ooh-statement/v2/bill/getbillpage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询确认计费项
 * @param {Object} data
 */
export function getfeeitempage (data) {
  return request({
    url: '/ooh-statement/v2/bill/getfeeitempage',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 查询确认计费项[运维]（分页）
 * @param {Object} data
 */
export function getmspfeeitempage (data) {
  return request({
    url: '/ooh-statement/v2/bill/getmspfeeitempage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 创建结算单
 * @param {Object} data
 */
export function createbill (data) {
  return request({
    url: '/ooh-statement/v2/bill/createbill',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
* 删除结算单
* @param {Object} data
*/
export function deletebill (data) {
  return request({
    url: '/ooh-statement/v2/bill/deletebill',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 提交结算单
 * @param {Object} data
 */
export function submitbill (data) {
  return request({
    url: '/ooh-statement/v2/bill/submitbill',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取某结算单概要
 * @param {Object} data
 */
export function getbilloutline (data) {
  return request({
    url: '/ooh-statement/v2/bill/getbilloutline',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 批量添加或者移除计费项
 * @param {Object} data
 */
export function batchaddorremovefeeitemlist (data) {
  return request({
    url: '/ooh-statement/v2/bill/batchaddorremovefeeitemlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取结算账单可调整的费用类型列表
 * @param {Object} data
 */
export function getadjustablefeetypelist (data) {
  return request({
    url: '/ooh-statement/v2/bill/getadjustablefeetypelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 添加或修改调整项到结算账单中
 * @param {Object} data
 */
export function addorupdateadjustfeeitemtobill (data) {
  return request({
    url: '/ooh-statement/v2/bill/addorupdateadjustfeeitemtobill',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 查询结算账单下的调整项计费项
 * @param {Object} data
 */
export function getadjustfeeitempage (data) {
  return request({
    url: '/ooh-statement/v2/bill/getadjustfeeitempage',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 查询交易结算账单下的调整项计费项（分页）
 * @param {Object} data
 */
export function getdwadjustfeeitempage (data) {
  return request({
    url: '/ooh-statement/v2/bill/getdwadjustfeeitempage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 将调整项从结算账单中移除
 * @param {Object} data
 */
export function removeadjustfeeitemfrombill (data) {
  return request({
    url: '/ooh-statement/v2/bill/removeadjustfeeitemfrombill',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 更改结算账单的备注
 * @param {Object} data
 */
export function updatebill (data) {
  return request({
    url: '/ooh-statement/v2/bill/updatebill',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取某结算单费用结构(含税率信息)
 * @param {Object} data
 */
export function getbillamountstructurewithtaxratio (data) {
  return request({
    url: '/ooh-statement/v2/bill/getbillamountstructurewithtaxratio',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 为结算账单的费用类型设置税率
 * @param {Object} data
 */
export function setbilltaxratio (data) {
  return request({
    url: '/ooh-statement/v2/bill/setbilltaxratio',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取结算统计信息
 * @param {Object} data
 */
export function getbillsSummary (data) {
  return request({
    url: '/ooh-statement/v2/bill/getbillssummary',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 重新编辑结算账单
 * @param {Object} data
 */
export function reeditbill (data) {
  return request({
    url: '/ooh-statement/v2/bill/reeditbill',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 查询审批通过的有效合同,宁波临时功能
 * @param {Object} data
 */
export function getValidContractList (data) {
  return request({
    url: '/ooh-statement/v2/bill/getValidContractList',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取msp任务公司来源
 * @param {Object} data
 */
export function listMspCreateCompany (data) {
  return request({
    url: '/ooh-statement/v2/bill/listMspCreateCompany',
    method: 'post',
    data: qs.stringify(data)
  })
}
